<!-- HosFollowUp 院内随访 --> 
<template>
    <div>
        <isHeader />
        <isBanner :title="title" :desc="desc" :src="src"/>
        <isPro1 v-runshow/>
        <isPro2 v-runshow/>
        <isPro3 v-runshow/>
        <isFooter />
    </div>
</template>

<script>
    import isHeader from '@/components/header/index.vue';
    import isBanner from '@/components/Solution/isBanner.vue';
    import isPro1 from '@/components/HosFollow/isPro1.vue';
    import isPro2 from '@/components/HosFollow/isPro2.vue';
    import isPro3 from '@/components/HosFollow/isPro3.vue';
    import isFooter from '@/components/footer/index.vue';
    import imgForBanner from '@/assets/Solution/followup_bannernew.png';
    export default {
        name: 'HosFollowUp',
        data: () => {
            return {
                title: '院内、院外随访',
                desc: '以院内、院外随访为着力点，强化随访业务在医院智慧服务和患者慢病管理体系中的纽带作用',
                src: imgForBanner,
            }
        },
        components: {
            isHeader,
            isBanner,
            isPro1,
            isPro2,
            isPro3,
            isFooter,
        }
    }
</script>