<template>
  <div class="isPro2">
    <div class="centerBox">
      <div class="title">
        <p>{{ title }}</p>
        <p>
          {{ desc }}
        </p>
        <p></p>
      </div>

      <!-- pc -->
      <ul class="pc">
        <li class="isPart_1">
          <div class="mark">
            <div class="partTitle">{{ solution.title }}</div>
            <div class="partDesc">{{ solution.desc }}</div>
            <ul>
              <li v-for="item in solution.list" :key="item.key">
                <img :src="require('@/assets/Solution/isTips.png')" />
                {{ item.text }}
              </li>
            </ul>
          </div>
        </li>
        <li class="isPart_1">
          <div class="mark">
            <div class="partTitle">{{ client.title }}</div>
            <div class="partDesc">{{ client.desc }}</div>
          </div>
        </li>
      </ul>

      <!-- mobile -->
      <div class="mobile">
        <div class="isswiper_pro2_3">
          <div class="swiper-wrapper">
            <div class="swiper-slide swip_1">
              <div class="mark">
                <div>{{ solution.title }}</div>
                <div>{{ solution.desc }}</div>
                <ul>
                  <li v-for="item in solution.list" :key="item.key">
                    <img :src="require('@/assets/Solution/isTips.png')" />
                    {{ item.text }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="swiper-slide swip_2">
              <div class="mark">
                <div>{{ client.title }}</div>
                <div>{{ client.desc }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "我们的解决方案",
      desc: "立足院内随访业务  辐射健康管理全价值链",

      solution: {
        title: "解决方案",
        desc: "立足院内随访业务，对接院内 HIS、电子病历等系统，通过AI随访路径规划的方式，为患者提供精细化随访、个性化宣教、科学健康指导等服务。",
        list: [
          {
            key: 1,
            text: "随访路径规划",
          },
          {
            key: 2,
            text: "智能知识库",
          },
          {
            key: 3,
            text: "全病程访视监测",
          },
          {
            key: 4,
            text: "个性化宣教方案",
          },
        ],
      },

      client: {
        title: "客户价值",
        desc: "极大缩减医疗机构、医务人员的工作量，提高医疗管理服务的覆盖率，实时存储患者的随访数据，赋能慢病诊疗和医院智慧化建设。",
      },
    };
  },
  mounted() {
    var mySwiper = new Swiper(".isswiper_pro2_3", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
    });
  },
};
</script>

<style scoped>
.isPro2 {
  width: 100%;
}
.centerBox {
  width: 100%;
  margin: 0 auto;
}

.title {
  height: 3rem;
  background: #373948;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  color: #ffffff;
}

.title p:nth-child(1) {
  font-weight: 900;
  font-size: 0.3rem;
  line-height: 0.42rem;
}
.title p:nth-child(2) {
  font-size: 0.38rem;
  padding: 0.16rem 0 0.25rem 0;
}

.title p:nth-child(3) {
  width: 0.74rem;
  height: 0.03rem;
  background: #1aa858;
}

.mobile {
  display: none;
}

.pc {
  font-size: 0;
}

.pc .isPart_1 {
  width: 50%;
  display: inline-block;
  min-height: 5.8rem;
  color: #fff;
  box-sizing: border-box;
  vertical-align: top;
  padding-left: 7%;
  position: relative;
}
.mark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  padding-left: 7%;
  box-sizing: border-box;
}

.pc .isPart_1:nth-child(1) {
  background: url("../../assets/Solution/isPro2_3_1.png") no-repeat;
  background-size: cover;
}

.pc .isPart_1:nth-child(2) {
  background: url("../../assets/Solution/isPro2_3_2.png") no-repeat;
  background-size: cover;
}

.pc .isPart_1 .partTitle {
  font-style: normal;
  font-weight: 900;
  font-size: 0.38rem;
  line-height: 0.53rem;
  margin-top: 12.5%;
}
.pc .isPart_1 .partDesc {
  font-style: normal;
  font-weight: normal;
  font-size: 0.22rem;
  line-height: 0.36rem;
  width: 70%;
  margin-top: 0.24rem;
}

.pc .isPart_1 ul {
  margin-top: 0.48rem;
}

.pc .isPart_1 ul li {
  font-style: normal;
  font-weight: normal;
  font-size: 0.24rem;
  line-height: 0.36rem;
  list-style: none;
}
@media screen and (max-width: 1024px) {
  .title {
    height: auto;
    padding: 0.6rem 0;
  }
  .title p:nth-child(1) {
    line-height: 0.67rem;
    font-size: 0.48rem;
  }
  .title p:nth-child(2) {
    max-width: 90%;
    line-height: 0.67rem;
    font-size: 0.38rem;
    text-align: center;
  }

  .mobile {
    display: block;
    overflow: hidden;
  }

  .pc {
    display: none;
  }

  .mobile .swiper-slide {
    height: 6.7rem;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: normal;
    color: #ffffff;
    position: relative;
    /* width: 100%; */
  }

  .title p:nth-child(3) {
    width: 1.3rem;
    height: 0.03rem;
    background: #1aa858;
  }

  .swip_1 {
    background: url("../../assets/Solution/isPro2_3_1.png") no-repeat;
    background-size: cover;
  }

  .swip_1 div div:nth-child(1) {
    font-weight: 900;
    font-size: 0.38rem;
    line-height: 0.53rem;
    /* padding-top: 10%; */
    color: #ffffff;
    padding: 10% 8% 0;
  }

  .swip_1 div div:nth-child(2) {
    font-weight: 400;
    font-size: 0.3rem;
    line-height: 0.54rem;
    margin-top: 0.24rem;
    padding: 0 8%;
  }

  .swip_1 ul {
    list-style: none;
    margin-top: 0.2rem;
    padding: 0 8%;
  }

  .swip_1 ul li {
    font-weight: 400;
    font-size: 0.18rem;
    line-height: 240%;
    display: flex;
    align-items: center;
  }
  .swip_1 ul li img {
    margin-right: 4px;
    width: 0.1rem;
    vertical-align: middle;
  }

  .swip_2 {
    background: url("../../assets/Solution/isPro2_3_2.png") no-repeat;
    background-size: cover;
  }

  .swip_2 div div:nth-child(1) {
    font-weight: 900;
    font-size: 0.38rem;
    line-height: 0.53rem;
    /* padding-top: 10%; */
    color: #ffffff;
    padding: 10% 8% 0;
  }

  .swip_2 div div:nth-child(2) {
    font-weight: 400;
    font-size: 0.3rem;
    line-height: 0.54rem;
    margin-top: 0.24rem;
    padding: 0 8%;
  }
}
</style>
