<template>
  <div class="banner" style="overflow: hidden">
    <img class="banner-image" v-runbannershow :src="src" alt="" />
    <div class="main-banner" v-runbannershow>
      <div class="banner-content">
        <p>{{ this.title }}</p>
        <p>{{ this.desc }}</p>
        <p>
          <!-- <button class="btn-banner">在线咨询</button> -->
          <button
            class="btn-banner"
            @click="$store.commit('changeAppointModal', true)"
          >
            预约体验
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "isBanner",
  props: ["title", "desc", "src"],
};
</script>

<style scoped>
.banner {
  width: 100%;
  position: relative;
}

.banner-image {
  width: 100%;
  min-height: 3.9rem;
}

.main-banner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
}

.banner-content {
  /* width: 95%; */
  /* max-width: 1540px; */
  width: 88%;
  margin: 0 auto;
  color: #ffffff;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
}

.banner-content p:nth-child(1) {
  font-size: 0.52rem;
  line-height: 0.73rem;
  margin-bottom: 0.16rem;
  /* padding-top: 10%; */
}
.banner-content p:nth-child(2) {
  font-size: 0.22rem;
  max-width: 5.6rem;
  line-height: 0.31rem;
  margin-bottom: 0.4rem;
}
.btn-banner {
  width: 1.64rem;
  height: 0.58rem;
  background: transparent;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 0.18rem;
  color: #fff;
  cursor: pointer;
}
.btn-banner:nth-child(1) {
  margin-right: 0.32rem;
}

@media screen and (max-width: 1024px) {
  .banner-image {
    object-fit: cover;
    min-height: 5.6rem;
  }

  .banner-content {
    width: 88%;
  }
  .banner-content p:nth-child(1) {
    padding-top: 1rem;
    font-size: 0.5rem;
    margin-bottom: 0.24rem;
  }
  .banner-content p:nth-child(2) {
    font-size: 0.28rem;
    margin-bottom: 0.45rem;
    line-height: 0.39rem;
    max-width: 100%;
  }
  .btn-banner {
    padding: 0.1rem 0.3rem 0.12rem 0.3rem;
    height: auto;
    margin-right: 0.2rem !important;
    width: auto;
    font-size: 0.36rem;
  }
}
</style>